export const menus = [
    {
        title: 'Solutions',
        items: [
            { label: 'Vulnerabilities intelligence', url: '/feed' },
            { label: 'Perimeter control tool', url: '/perimeter-control' },
            { label: 'Linux scanner', url: '/linux' },
            { label: 'Windows scanner', url: '/windows' },
            { label: 'Developers SDK', url: '/sdk' },
            { label: 'Security Intelligence feeds', url: '/mssp' }
        ]
    },
    {
        title: 'Database',
        items: [
            {
                label: 'Vulnerabilities',
                url: '/search?query=viewCount:[50%20TO%20*]%20order:viewCount%20last%207%20days'
            },
            { label: 'Exploits', url: '/search?bulletinFamily:exploit%20order:published' },
            { label: 'Security News', url: '/search?query=(type:thn%20OR%20type:threatpost)%20order:published' },
            { label: 'BugBounty', url: '/search?query=bulletinFamily:bugbounty%20order:published' },
            { label: 'Wild Exploited', url: '/search?query=enchantments.exploitation.wildExploited:true' },
            {
                label: 'Top Vulnerabilities',
                url: '/search?query=cvss3.cvssV3.baseScore:[6%20TO%2010]%20AND%20order:published'
            },
            { label: 'CVE Feed', url: '/search/types/cve' }
        ]
    },
    {
        title: 'Resources',
        items: [
            { label: 'Statics & Sources', url: '/stats' },
            { label: 'Plugins', url: '/products' },
            { label: 'API docs', url: '/docs/API_wrapper/api/' },
            { label: 'FAQ', url: 'https://vulners.com/faq' },
            { label: 'Blog', url: 'https://vulners.com/blog' },
            { label: 'Glossary', url: 'https://vulners.com/glossary' }
        ]
    },
    {
        title: 'Company',
        items: [
            { label: 'About', url: '/company/about-us' },
            { label: 'Contacts', url: '/company/contacts' },
            { label: 'Pricing', url: '/pricing' },
            { label: 'EULA', url: '/static/docs/eula.pdf', target: '_blank' },
            { label: 'Privacy Policy', url: '/static/docs/privacy_policy.html', target: '_blank' },
            { label: 'Submission Policy', url: '/static/docs/submission_policy.html', target: '_blank' },
            { label: 'OpenSource', url: 'https://github.com/vulnerscom' }
        ]
    }
];
