import { makeStyles } from 'tss-react/mui';

export const BREAK_POINT = 768;

export default makeStyles({ name: 'Footer' })((theme) => ({
    root: {
        padding: '60px 60px 0',
        display: 'flex',
        flexDirection: 'column',
        gap: 40,
        backgroundColor: '#fff',
        color: '#282D31',
        '& a': {
            color: '#282D31'
        },
        '& p': {
            margin: 0
        }
    },
    section: {
        display: 'flex',
        alignItems: 'self-start',
        gap: 32,
        maxWidth: 1280,
        [theme.breakpoints.down(BREAK_POINT)]: {
            flexDirection: 'column'
        }
    },
    column: {
        display: 'flex',
        flexDirection: 'column',
        flex: 2
    },
    company: {
        gap: 40,
        flex: 3,
        alignItems: 'flex-start'
    },
    menu: {
        gap: 24
    },
    description: {
        color: '#8E8E8E',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '150%'
    },
    title: {
        color: '#8E8E8E',
        fontSize: 14,
        fontWeight: 600,
        lineHeight: '150%'
    },
    list: {
        padding: 0,
        margin: 0,
        listStyle: 'none'
    },
    item: {
        color: '#232323',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '140%',
        cursor: 'pointer',
        margin: '10px 0',
        '& a': {
            textDecoration: 'none'
        },
        '&:first-child': {
            marginTop: 0
        },
        '&:last-child': {
            marginBottom: 0
        }
    },
    copyright: {
        color: '#232323',
        fontSize: 14,
        fontWeight: 500,
        lineHeight: '27px',
        width: '100%',
        [theme.breakpoints.down(BREAK_POINT)]: {
            flexDirection: 'column',
            padding: '32px 0',
            borderTop: '1px solid #ECECEC'
        }
    },
    subSection: {
        display: 'flex',
        justifyContent: 'space-between',
        padding: '32px 0',
        borderTop: '1px solid #ECECEC',
        gap: 24,
        [theme.breakpoints.down(BREAK_POINT)]: {
            flexDirection: 'column'
        }
    },
    socialButtons: {
        marginRight: 64,
        display: 'flex',
        alignItems: 'center',
        gap: 12,
        [theme.breakpoints.down(BREAK_POINT)]: {
            marginRight: 'initial',
            flexWrap: 'wrap'
        }
    },
    socialButton: {
        padding: 12,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        borderRadius: 16,
        backgroundColor: '#F8F8F8',
        cursor: 'pointer',
        '&:hover': {
            backgroundColor: '#ECECEC'
        }
    }
}));
